import Carousel from 'react-bootstrap/Carousel';






function Intro() {
  
      
  return (

    <div style={{ width: '100%', height: 'auto' }}>
      <Carousel  variant="light" pause="false" bsPrefix="carousel" fade>
        <Carousel.Item>
            <img
            className="carouselImg d-block w-100 h-50"
            src="img/portfolio/carousel01.jpg"
            alt="First slide"
            />
            <Carousel.Caption>
            <h1 className='title'>SHINE ON CLEANING</h1>
            <h3 className='subtitle'> A Connecticult Based Cleaning Services Specialist.</h3>
            </Carousel.Caption>
        {/*
        
        check botstrao.css for page indicator
        ----
        .carousel-indicators .active {
                  display: none;****
                  
                }
        -----
             <Carousel.Item>
            <img
            className="carouselImg d-block w-100 h-50 text-white"
            src="img/portfolio/carousel02.jpg"
            alt="Second slide"
            />

            <Carousel.Caption>
            <h1 className='title'>SHINE ON CLEANING</h1>
            <h3 className='subtitle'> A Connecticult Based Cleaning Services Specialist.</h3>
            </Carousel.Caption>

        </Carousel.Item>
        <Carousel.Item>
            <img
            className="carouselImg d-block w-100 h-50"
            src="img/portfolio/carousel03.jpg"
            alt="Third slide"
            />

            <Carousel.Caption>
            <h1 className='title'>SHINE ON CLEANING</h1>
            <h3 className='subtitle'> A Connecticult Based Cleaning Services Specialist.</h3>
       
          
            </Carousel.Caption>
        </Carousel.Item>
        
        */ }
        </Carousel.Item>
   
        </Carousel>
    </div>

  
  );
}

export default Intro;