import React  from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


export const PostConstruction = (props) => {
   
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };



return (
  <div className="serviceBody max-w-sm">
  <div className="card-text1">
    <h1>Get the Cleaning Services You Need</h1>
  </div>

  <div className="card-text2">
    <h3>Choose the specific areas you require cleaning for and omit the ones that are unnecessary.</h3>
  </div>

  <div className="card-container">
    <img className="img-services-residential" src="../img/portfolio/post-construction-cleaning.jpg" alt="serviceimg"/>
  </div>

  <div className="card-body">
  
    
    <Card className="cardd">
      <Card.Header className="cardHeader">Post-Construction Cleaning: </Card.Header>
      <ListGroup className="card-text" variant="flush">
        <ListGroup.Item> Dust Vanquishing: Our expert team employs cutting-edge techniques to banish even the tiniest speck of dust, ensuring your spaces are immaculate.</ListGroup.Item>
        <ListGroup.Item>Debris Eradication: Construction residues are a thing of the past with our thorough debris removal, leaving your area spotless and safe.</ListGroup.Item>
        <ListGroup.Item>Stain Obliteration: From stubborn paint splatters to accidental spills, we specialize in erasing all traces and blemishes, restoring surfaces to their original glory.</ListGroup.Item>
        <ListGroup.Item>Polished Perfection: We take pride in restoring your fixtures, windows, and surfaces to a mirror-like shine, amplifying the beauty of your space.</ListGroup.Item>
        <ListGroup.Item>Air Purity Enhancement: Our advanced equipment ensures the circulation of fresh and clean air, contributing to a healthier environment for your clients or occupants.</ListGroup.Item>
        
     
      </ListGroup>
    </Card>
  </div>

  <button id="btn-back-to-top" className="scroll-to-top" onClick={handleScrollToTop}>
    <i className="fa fa-arrow-up"></i>
  </button>
</div>
  );
};
  