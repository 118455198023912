import React  from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


export const Moveinout = (props) => {
   
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };



return (
  <div className="serviceBody max-w-sm">
  <div className="card-text1">
    <h1>Get the Cleaning Services You Need</h1>
  </div>

  <div className="card-text2">
    <h3>Choose the specific areas you require cleaning for and omit the ones that are unnecessary.</h3>
  </div>

  <div className="card-container">
    <img className="img-services-residential" src="../img/portfolio/movein.jpeg" alt="serviceimg"/>
  </div>

  <div className="card-body">
  
    
    <Card className="cardd">
      <Card.Header className="cardHeader">Move-in/Move-out Cleaning: </Card.Header>
      <ListGroup className="card-text" variant="flush">
        <ListGroup.Item>Cleaning and sanitizing all bathrooms, including toilets, sinks, showers, and tubs.</ListGroup.Item>
        <ListGroup.Item>Cleaning the kitchen, including wiping down all surfaces, cleaning appliances, and scrubbing the sink.</ListGroup.Item>
        <ListGroup.Item>Dusting and wiping all surfaces, including furniture, light fixtures, and windowsills.</ListGroup.Item>
        <ListGroup.Item>Vacuuming and mopping all floors, including under furniture and in corners.</ListGroup.Item>
        <ListGroup.Item>Cleaning the interior and exterior of all cabinets and drawers.</ListGroup.Item>
        <ListGroup.Item>Clean and wash sink, in addition to polishing the faucets</ListGroup.Item>
        <ListGroup.Item>Cleaning the interior and exterior of all appliances, including the oven, fridge, and dishwasher.</ListGroup.Item>
     
      </ListGroup>
    </Card>
  </div>

  <button id="btn-back-to-top" className="scroll-to-top" onClick={handleScrollToTop}>
    <i className="fa fa-arrow-up"></i>
  </button>
</div>
  );
};
  