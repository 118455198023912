import React  from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


export const Commercial = (props) => {
   

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


return (
   
<div className="serviceBody max-w-sm">

  <div className="card-text1">
    <h1>Get the Cleaning Services You Need</h1>

  </div>
  
  <div className="card-text2">
   
    <h3>Choose the specific areas you require cleaning for and omit the ones that are unnecessary.</h3>
  </div>
    <div className="card-container">
      
  
      <img  className="img-services-residential" src="../img/portfolio/IMG_5717.JPG" alt="serviceimg"/>
            
    </div>
    <div className="card-body2">
      <Card className="card" style={{ width: '100rem' }}>
        <Card.Header className="cardHeader">Offices </Card.Header>
        <ListGroup className="card-text" variant="flush">
          <ListGroup.Item > Vacuum all carpets</ListGroup.Item>
          <ListGroup.Item >Empty and clean wastebaskets</ListGroup.Item>
          <ListGroup.Item >Clean glass surfaces</ListGroup.Item>
          <ListGroup.Item >Wash drinking fountains</ListGroup.Item>
          <ListGroup.Item >Dust and wipe furniture, fixtures, and pictures</ListGroup.Item>
          <ListGroup.Item>Remove cobwebs</ListGroup.Item>
          <ListGroup.Item>Sweep and mop all tile and wood flooring</ListGroup.Item>
          <ListGroup.Item> Sweep and mop all tile and wood flooring</ListGroup.Item>
          <ListGroup.Item> Clean microwaves, sinks, and counter areas</ListGroup.Item>
          <ListGroup.Item> Sweep and mop kitchen floors</ListGroup.Item>
          <ListGroup.Item>Clean and sanitize bathrooms</ListGroup.Item>
          <ListGroup.Item> Clean all kitchen appliances and cabinets</ListGroup.Item>
          <ListGroup.Item> Empty and clean towel receptacles</ListGroup.Item>
          <ListGroup.Item> Fill toilet tissue, soap, and towel dispensers</ListGroup.Item>


      </ListGroup>
        
      </Card>
    </div>
    <button
        id="btn-back-to-top"
        className="scroll-to-top "
        onClick={handleScrollToTop}
      >
        <i className="fa fa-arrow-up"></i>
      </button>
  </div>
  );
};
  