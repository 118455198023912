
import React from "react";
import { Link } from "react-router-dom";

export const Navigation = () => {



  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container">
        <a className="navbar-brand" href="/">
          
          <img
              src="img/Logo_Horizontal_01.svg"
             
              width="200"
              className="logo"
              alt="React Bootstrap logo"
            />
        
        
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/"
                onClick={() => {
                  window.scroll({ top: 0, left: 0, behavior: "smooth" });
                }}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/#about"
                onClick={() => {
                  window.scroll({
                    top: document.getElementById("about").offsetTop,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
            <Link
                className="nav-link"
                to="/#services"
                onClick={() => {
                  window.scroll({
                    top: document.getElementById("services").offsetTop,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Services
              </Link>
            </li>
     
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/#contact"
                onClick={() => {
                  window.scroll({
                    top: document.getElementById("contact").offsetTop,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
