  import { useState } from "react";
  import emailjs from "emailjs-com";
  import React from "react";
  import CleaningRagAnimation from "./cleaningrag";
  import {LoadingButton} from "./loadingButton"; // import LoadingButton component
  import Button from 'react-bootstrap/Button';



  const initialState = {
    name: "",
    email: "",
    message: "",
    phone: ""
  };
  
  export const Contact = (props) => {
    const [{ name, email, message, phone }, setState] = useState(initialState);
    const [isSubmitting, setSubmitting] = useState(false); // add isSubmitting state
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
    };
  
    const clearState = () => setState({ ...initialState });
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(name, email, message, phone);
      setSubmitting(true); // set isSubmitting to true
      emailjs
      .sendForm("service_epmozdi", "template_fqbm0ha", e.target, "iJdNpjNolzoyKfHBK")
      .then(
        (result) => {
          console.log(result.text);
          setState({ ...initialState }); // reset form
          clearState();
          setSubmitting(false); // set isSubmitting to false
        },
        (error) => {
          console.log(error.text);
          setSubmitting(false); // set isSubmitting to false
        }
      );
    };
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
              
            
              <div className="section-title">
                <CleaningRagAnimation />
              </div>

                <form name="sentMessage" validate onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
              
                    <div className="col-md-6">
                      <div className="form-group">
                    
                        <input
                          type="phone"
                          id="phone"
                          name="phone"
                          className="form-control"
                          placeholder="Phone"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div >
                      <div className="form-group">
                      <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div>
                  {isSubmitting ? (
                  <LoadingButton />
                ) : (
                  <Button variant="secondary" type="submit">
                    Submit
                  </Button>
                )}

                  
                </form>
              </div>
            </div>
            <div className="contactCard col-md-3 col-md-offset-1 pt-2 pb-2 text-center">
            <div className="contact-item pb-3">
            <h3 className="pb-4 ">Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker fa-lg text-danger"></i> Address:
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item pb-3">
                <p>
                  <span>
                    <i className="fa fa-phone fa-lg text-info"></i> Phone:
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o fa-lg text-success " size="lg"></i>Email:
                  
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a href={props.data ? props.data.facebook : "/"}>
                        <i className=" fa fa-facebook"></i>
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  };




