import React, { useState, useEffect } from "react";

import { About } from "./about";
import { Contact } from "./contact";
import { Services } from "./services";
import JsonData from "../data/data.json";
import Intro from "./carousel";
import {Footer} from "./footer"


export const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  let mybutton = document.getElementById("btn-back-to-top");

  window.onscroll = function () {
    handleIcon();
  };

  const handleIcon = () => {
    if (
      document.body.scrollTop > 0 ||
      document.documentElement.scrollTop > 0
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <Intro />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      
      <Contact data={landingPageData.Contact} />
      <button
        id="btn-back-to-top"
        className="scroll-to-top"
        onClick={handleScrollToTop}
      >
        <i className="fa fa-arrow-up"></i>
      </button>
  
   <Footer />
    </div>
    
  );
};
