import { useState, useEffect } from 'react';

function CleaningRagAnimation() {
  const [isAnimating, setIsAnimating] = useState(false);
  const [textColor, setTextColor] = useState('yellow');

  useEffect(() => {
    if (isAnimating) {
      setTimeout(() => {
        setTextColor('white');
      }, 1000);
    } else {
      setTextColor('yellow');
    }
  }, [isAnimating]);

  const handleMouseEnter = () => {
    setIsAnimating(false);
    setTextColor('yellow');
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
    setTextColor('white');
  };

  return (
    <div className="section-title">
      <div className="cleaning-rag-container" onMouseEnter={handleMouseEnter}>
        <img
          src="https://img.icons8.com/color/256/cleaning-a-surface.png"
          alt="cleaning rag"
          className={`cleaning-rag ${isAnimating && 'cleaning-rag-animating'}`}
          onAnimationEnd={handleAnimationEnd}
        />
        <h2 className={`text ${isAnimating ? 'text-normal' : ''}`} style={{ color: textColor }}>
          Get in touch! 
        </h2>
      </div>
      <p>
        Please fill out the form below to send us an email and we will get back to you as soon as
        possible.
      </p>
      <p className='text-black font-bold'>
        Contact us to schedule a FREE estimate at your home!
      </p>
    </div>
  );
}

export default CleaningRagAnimation;
