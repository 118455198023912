  export const Footer = () => {
    



      return (
          <div id="footer">
          
          <div className="container text-center">
            <p>
              
              &copy; {(new Date().getFullYear())} Designed by
              <a className="aFooter"href="https://www.raphaeldacruz.com"> Raphael da Cruz ™{" "}</a>
              
            </p>
          </div>
        </div>
      );
    };

