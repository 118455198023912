import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Routes, Route } from "react-router-dom";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

import { Home } from "./components/home";
import { Residential } from "./components/services/residential";
import { Commercial } from "./components/services/commercial";
import { DeepClean } from "./components/services/deepCleaning";
import { Moveinout } from "./components/services/moveinout";
import { PostConstruction} from "./components/services/postConstruction";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/services/residential"
          element={<Residential data={landingPageData.Services} />}
        />
        <Route
          path="/services/commercial"
          element={<Commercial data={landingPageData.Services} />}
        />
         <Route
          path="/services/deepcleaning"
          element={<DeepClean data={landingPageData.Services} />}
        />
         <Route
          path="/services/moveinout"
          element={<Moveinout data={landingPageData.Services} />}
        />
         <Route
          path="/services/postconstruction"
          element={<PostConstruction data={landingPageData.Services} />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
    </div>
  );
};

export default App;
