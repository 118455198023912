import React  from "react";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';


export const Residential = (props) => {
   
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };



return (
  <div className="serviceBody max-w-sm">
  <div className="card-text1">
    <h1>Get the Cleaning Services You Need</h1>
  </div>

  <div className="card-text2">
    <h3>Choose the specific areas you require cleaning for and omit the ones that are unnecessary.</h3>
  </div>

  <div className="card-container">
    <img className="img-services-residential" src="../img/portfolio/service-residential.JPG" alt="serviceimg"/>
  </div>

  <div className="card-body">
    <Card className="cardd">
      <Card.Header className="cardHeader">Kitchen </Card.Header>
      <ListGroup className="card-text" variant="flush">
        <ListGroup.Item>Clean refrigerator exterior (inside only upon request) </ListGroup.Item>
        <ListGroup.Item>Clean oven exterior (inside only upon request) </ListGroup.Item>
        <ListGroup.Item>Clean microwave inside and out, removing stains and marks where possible</ListGroup.Item>
        <ListGroup.Item>Clean and wash all countertops and surfaces, polishing where necessary</ListGroup.Item>
        <ListGroup.Item>Clean and wash sink, while making sure to remove limescale and polish</ListGroup.Item>
        <ListGroup.Item>Clean and wipe the doors while removing marks and fingerprints</ListGroup.Item>
        <ListGroup.Item>Dust lamp shades and light switches</ListGroup.Item>
        <ListGroup.Item>Vacuum and mop floor</ListGroup.Item>
      </ListGroup>
    </Card>

    <Card className="cardd">
      <Card.Header className="cardHeader">Bedroom </Card.Header>
      <ListGroup className="card-text" variant="flush">
        <ListGroup.Item>Dust and polish all tops and surfaces</ListGroup.Item>
        <ListGroup.Item>Wipe picture frames and lamp shades</ListGroup.Item>
        <ListGroup.Item>Clean and wipe baseboards</ListGroup.Item>
        <ListGroup.Item>Vacuum and mop floors</ListGroup.Item>
      </ListGroup>
    </Card>

    <Card className="cardd">
      <Card.Header className="cardHeader">Bathroom </Card.Header>
      <ListGroup className="card-text" variant="flush">
        <ListGroup.Item>Clean and disinfect toilets inside and out, polishing where necessary</ListGroup.Item>
        <ListGroup.Item>Clean and wash the bathtub, shower, and faucet</ListGroup.Item>
        <ListGroup.Item>Clean and wash tiles and remove lime scale</ListGroup.Item>
        <ListGroup.Item>Clean and polish mirrors</ListGroup.Item>
        <ListGroup.Item>Clean cabinets</ListGroup.Item>
        <ListGroup.Item>Clean and wash sink, in addition to polishing the faucets</ListGroup.Item>
        <ListGroup.Item>Vacuum and wash floors</ListGroup.Item>
        <ListGroup.Item>Clean and wipe the doors while removing marks and fingerprints</ListGroup.Item>
      </ListGroup>
    </Card>

  </div>

  <button id="btn-back-to-top" className="scroll-to-top" onClick={handleScrollToTop}>
    <i className="fa fa-arrow-up"></i>
  </button>
</div>
  );
};
  